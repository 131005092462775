
import React, { useEffect, useState } from "react";

const Counter = ({ endValue, title, duration }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        let start = 0;
        const increment = endValue / (duration / 100);
    
        const counterInterval = setInterval(() => {
          start += increment;
          if (start >= endValue) {
            clearInterval(counterInterval);
            start = endValue; 
          }
          setValue(Math.floor(start));
        }, 100);
    
        return () => clearInterval(counterInterval);
      }, [endValue, duration]);
    return (
        <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-3 vc_col-md-6 vc_col-xs-12">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            <div className="eltdf-counter-holder" style={{ opacity: 1 }}>
              <div className="eltdf-counter-inner">
                <span
                  className="eltdf-counter eltdf-zero-counter eltdf-section-title"
                  style={{ fontSize: 30}}>
                  {value}
                  {title !== "return" && "+"}
                </span>
                <h5 className="eltdf-counter-title eltdf-section-title">
                  {title}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default Counter