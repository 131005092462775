import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "elegant-icons/style.css";
import { BASE_URL, BASE_IMAGE_URL } from "./config";
import { useParams, useNavigate } from "react-router-dom";
import { addToCart } from "../Api/CartApi/AddToCartApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { ClipLoader } from 'react-spinners';
const ProductDetails = () => {
  
  const { product_slug } = useParams();
  const [productData, setProductData] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeTab, setActiveTab] = useState("description");
  const [rating, setRating] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewDescription, setReviewDescription] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [reviews, setReviews] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  
  useEffect(() => { 
    const fetchProductData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/product-detail/${product_slug}`);
     
      const product = response.data.data.product;
      setProductData(product);
      if (product && product.product_name) {
        document.title = product.product_name+' | SYNTH - SOUNDS OF STREETWEAR';
      } else {
        console.error("Product name not found in the response.");
        document.title = "Product Not Found";
      }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };
    fetchProductData();
  }, [product_slug]);

  useEffect(() => {
    const storedName = localStorage.getItem("name");
    const storedEmail = localStorage.getItem("email");

    if (storedName) setName(storedName);
    if (storedEmail) setEmail(storedEmail);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/review-list/${product_slug}`);
        if (response.data.success) {
          setReviews(response.data.data.review);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, [product_slug, token]);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/related-product-list/${product_slug}`
        );
        if (response.data.success) {
          setProducts(response.data.data.products);
        }
      } catch (error) {
        console.error("Error fetching related products:", error);
      }
      finally{
        setLoading(false);
      }
    };

    fetchRelatedProducts();
  }, [product_slug]);

 
  if (loading) {
    return (
      <div className="loader-container">
        <ClipLoader size={50} />
      </div>
    );
  }
  const handleSubmit = async (productId) => {
    try {
      const result = await addToCart(productId, token, navigate);
      if (result.success) {
        const message = result.data.carts;
        toast.success("Product successfully added in cart", message, {
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("There was an error adding your product", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("Plasse first login then add the product", {
        autoClose: 3000,
      });
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleClick = (value) => {
    setRating(value);
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BASE_URL}/add-review`,
        {
          product_id: productData.id,
          rating,
          review_title: reviewTitle,
          review_description: reviewDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        toast.success("Review added successfully", {
          autoClose: 3000,
        });
      } else {
        if (response.data.data && response.data.data.error) {
          toast.error(response.data.data.error, {
            autoClose: 3000,
          });
        } else {
          toast.error("Failed to add review.", {
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.error
      ) {
        toast.error(error.response.data.data.error, {
          autoClose: 3000,
        });
      } else {
        console.error("Error adding review:", error);
        toast.error("An error occurred while adding the review.", {
          autoClose: 3000,
        });
      }
    }
  };

  
  const containerStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5, 
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5em', 
    maxHeight: '7.5em',
  };
  return (
    <>
      <div className="product-template-default single single-product postid-412 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woo-single-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-woo-single-switch-image eltdf-woo-single-thumb-on-left-side eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />
            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div
                  className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment"
                  style={{ height: 120, backgroundColor: "#fbf9f9" }}
                  data-height={120}
                >
                  <div className="eltdf-title-holder">
                    <div className="eltdf-container clearfix">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title">
                              <span>Product Details</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/">Home</Link>
                                  <span className="eltdf-delimiter">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <Link to="#">
                                    {productData.category.title}
                                  </Link>

                                  <span className="eltdf-delimiter">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current">
                                    {productData.product_name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container">
                  <div className="eltdf-container-inner clearfix">
                    <div className="woocommerce-notices-wrapper" />
                    <div
                      id="product-412"
                      className="product type-product post-412 status-publish first instock product_cat-summer-nude-collection product_tag-beige product_tag-catalog product_tag-lace has-post-thumbnail shipping-taxable purchasable product-type-simple"
                    >
                      <div className="eltdf-single-product-content">
                        <div
                          className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images"
                          
                        >
                          <Swiper
                            style={{
                              "--swiper-navigation-color": "#fff",
                              "--swiper-pagination-color": "#fff",
                            }}
                            spaceBetween={10}
                            navigation={true}
                            thumbs={{
                              swiper:
                                thumbsSwiper && !thumbsSwiper.destroyed
                                  ? thumbsSwiper
                                  : null,
                            }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper2"
                          >
                            <SwiperSlide>
                              <img
                                src={`${BASE_IMAGE_URL}/${productData.primary_image}`}
                                alt="Primary Product"
                              />
                            </SwiperSlide>

                            {productData.images.map((image, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={`${BASE_IMAGE_URL}/${image.images}`}
                                  alt="Product"
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>

                          <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                          >
                            <SwiperSlide>
                              <img
                                src={`${BASE_IMAGE_URL}/${productData.primary_image}`}
                                alt="Primary Product Thumbnail"
                              />
                            </SwiperSlide>

                            {productData.images.map((image, index) => (
                              <SwiperSlide key={index}>
                                <img
                                  src={`${BASE_IMAGE_URL}/${image.images}`}
                                  alt="Product Thumbnail"
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>

                        <div className="eltdf-single-product-summary">
                          <div className="summary entry-summary">
                            <h2
                              itemProp="name"
                              className="eltdf-single-product-title"
                            >
                              {productData.product_name}
                            </h2>
                            <p className="price">
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span>Price: ₹</span>
                                  {productData.base_price}
                                </bdi>
                              </span>
                            </p>
                            <div className="woocommerce-product-details__short-description synth-content" style={containerStyle}>
                              <p>{productData.description}</p>
                            </div>
                            <form
                              className="cart"
                              encType="multipart/form-data"
                            >
                              <div className="quantity eltdf-quantity-buttons">
                                <span className="eltdf-quantity-label">
                                  Quantity
                                </span>
                                1
                              </div>
                              <button
                                type="submit"
                                name="add-to-cart"
                                value={productData.id}
                                className="single_add_to_cart_button button alt wp-element-button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSubmit(productData.id);
                                }}
                              >
                                Add to cart
                              </button>
                            </form>
                            <div className="eltdf-single-product-share-wish">
                              {/* <div className="yith-wcwl-add-to-wishlist add-to-wishlist-412  no-icon wishlist-fragment on-first-load">
              <div className="yith-wcwl-add-button">
                <a href="#" className="add_to_wishlist single_add_to_wishlist" data-product-id={productData.id} data-product-type="simple" data-original-product-id={productData.id} data-title="Wishlist" rel="nofollow">
                  <span><i className="fa-regular fa-heart"></i> Wishlist </span>
                </a>
              </div>
            </div> */}
                              <div className="eltdf-woo-social-share-holder">
                                <span>Share</span>
                                <div className="eltdf-social-share-holder eltdf-list">
                                  <ul>
                                    <li className="eltdf-facebook-share">
                                      <Link
                                        itemProp="url"
                                        className="eltdf-share-link"
                                        to="#"
                                      >
                                        <i className="fa-brands fa-facebook"></i>
                                      </Link>
                                    </li>
                                    <li className="eltdf-twitter-share">
                                      <Link
                                        itemProp="url"
                                        className="eltdf-share-link"
                                        to="#"
                                      >
                                        <i className="fa-brands fa-twitter"></i>
                                      </Link>
                                    </li>
                                    <li className="eltdf-linkedin-share">
                                      <Link
                                        itemProp="url"
                                        className="eltdf-share-link"
                                        to="#"
                                      >
                                        <i className="fa-brands fa-linkedin-in"></i>
                                      </Link>
                                    </li>
                                    <li className="eltdf-pinterest-share">
                                      <Link
                                        itemProp="url"
                                        className="eltdf-share-link"
                                        to="#"
                                      >
                                        <i className="fa-solid fa-p"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="product_meta">
                              {/* <span className="sku_wrapper">
                                SKU:
                                <span className="sku">{productData.sku}</span>
                              </span> */}
                              <span className="posted_in">
                                Category:
                                <Link to="#" rel="tag">
                                  {productData.category.title}
                                </Link>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="woocommerce-tabs wc-tabs-wrapper">
                        <ul className="tabs wc-tabs" role="tablist">
                          <li
                            className={`description_tab ${
                              activeTab === "description" ? "active" : ""
                            }`}
                            id="tab-title-description"
                            role="tab"
                            aria-controls="tab-description"
                            onClick={() => handleTabClick("description")}
                          >
                            <a href="#description">Description</a>
                          </li>
                          <li
                            className={`additional_information_tab ${
                              activeTab === "additional_information"
                                ? "active"
                                : ""
                            }`}
                            id="tab-title-additional_information"
                            role="tab"
                            aria-controls="tab-additional_information"
                            onClick={() =>
                              handleTabClick("additional_information")
                            }
                          >
                            <a href="#specifications">specifications</a>
                          </li>
                          <li
                            className={`reviews_tab ${
                              activeTab === "reviews" ? "active" : ""
                            }`}
                            id="tab-title-reviews"
                            role="tab"
                            aria-controls="tab-reviews"
                            onClick={() => handleTabClick("reviews")}
                          >
                            <a href="#reviews">Reviews</a>
                          </li>
                        </ul>

                        <div
                          className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab"
                          id="tab-description"
                          role="tabpanel"
                          aria-labelledby="tab-title-description"
                          style={{
                            display:
                              activeTab === "description" ? "block" : "none",
                          }}
                        >
                          <h2>Description</h2>

                          <p>{productData.description}</p>
                        </div>

                        <div
                          className="woocommerce-Tabs-panel woocommerce-Tabs-panel--additional_information panel entry-content wc-tab"
                          id="tab-additional_information"
                          role="tabpanel"
                          aria-labelledby="tab-title-additional_information"
                          style={{
                            display:
                              activeTab === "additional_information"
                                ? "block"
                                : "none",
                          }}
                        >
                          <h2>Additional information</h2>

                          <p>{productData.specifications}</p>
                        </div>
                        <div
                          className="woocommerce-Tabs-panel woocommerce-Tabs-panel--reviews panel entry-content wc-tab"
                          id="tab-reviews"
                          role="tabpanel"
                          aria-labelledby="tab-title-reviews"
                          style={{
                            display: activeTab === "reviews" ? "block" : "none",
                          }}
                        >
                          <div id="reviews" className="woocommerce-Reviews">
                            <div id="comments">
                              <h2 className="woocommerce-Reviews-title">
                                {reviews.length} review
                                {reviews.length !== 1 && "s"} for
                                <span>Sunglasses</span>
                              </h2>
                              {reviews.length > 0 ? (
                                <ol className="commentlist">
                                  {reviews.map((review) => (
                                    <li
                                      key={review.id}
                                      className="comment even thread-even depth-1"
                                      id={`li-comment-${review.id}`}
                                    >
                                      <div
                                        id={`comment-${review.id}`}
                                        className="comment_container"
                                      >
                                        <img
                                          data-del="avatar"
                                          src="../wp-content/uploads/2017/08/nouser.png"
                                          alt="Avatar"
                                          style={{borderRadius:'50%'}}
                                        />
                                        <div className="comment-text">
                                          <div
                                            className="star-rating"
                                            role="img"
                                            aria-label={`Rated ${review.rating} out of 5`}
                                          >
                                            <span
                                              style={{
                                                width: `${
                                                  (review.rating / 5) * 100
                                                }%`,
                                              }}
                                            >
                                              Rated
                                              <strong className="rating">
                                                {review.rating}
                                              </strong>
                                              out of 5
                                            </span>
                                          </div>
                                          <p className="meta">
                                            <strong className="woocommerce-review__author">
                                              {review.review_title}
                                            </strong>
                                            <span className="woocommerce-review__dash">
                                           
                                              –
                                            </span>
                                            <time
                                              className="woocommerce-review__published-date"
                                              dateTime={new Date(
                                                review.created_at
                                              ).toISOString()}
                                            >
                                              {new Date(
                                                review.created_at
                                              ).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              })}
                                            </time>
                                          </p>
                                          <div className="description">
                                            <p>{review.review_description}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ol>
                              ) : (
                                <p>No review found</p>
                              )}
                            </div>

                            {isLoggedIn && (
                              <div id="review_form_wrapper">
                                <div id="review_form">
                                  <div id="respond" className="comment-respond">
                                    <span
                                      id="reply-title"
                                      className="comment-reply-title"
                                    >
                                      Add a review
                                      <small>
                                        <a
                                          rel="nofollow"
                                          id="cancel-comment-reply-link"
                                          href="/product/sunglasses/#respond"
                                          style={{ display: "none" }}
                                        >
                                          Cancel reply
                                        </a>
                                      </small>
                                    </span>

                                    <form
                                      onSubmit={handleSubmitReview}
                                      id="commentform"
                                      className="comment-form"
                                    >
                                      <p className="comment-notes">
                                        <span id="email-notes">
                                          Your email address will not be
                                          published.
                                        </span>
                                        <span className="required-field-message">
                                          Required fields are marked
                                          <span className="required">*</span>
                                        </span>
                                      </p>

                                      <div className="comment-form-rating">
                                        <label htmlFor="rating">
                                          Your rating&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <p className="stars selected">
                                          <span>
                                            {[1, 2, 3, 4, 5].map((value) => (
                                              <Link
                                                key={value}
                                                to="#"
                                                className={`star-${value} ${
                                                  value === rating
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleClick(value);
                                                }}
                                              >
                                                {value}
                                              </Link>
                                            ))}
                                          </span>
                                        </p>
                                        <select
                                          name="rating"
                                          id="rating"
                                          required
                                          style={{ display: "none" }}
                                          value={rating || ""}
                                          onChange={(e) =>
                                            handleClick(Number(e.target.value))
                                          }
                                        >
                                          <option value="">Rate…</option>
                                          <option value={5}>Perfect</option>
                                          <option value={4}>Good</option>
                                          <option value={3}>Average</option>
                                          <option value={2}>
                                            Not that bad
                                          </option>
                                          <option value={1}>Very poor</option>
                                        </select>
                                      </div>
                                      <p className="comment-form-author">
                                        <label htmlFor="author">
                                          ReviewTitle&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          id="review_title"
                                          name="review_title"
                                          type="text"
                                          size="30"
                                          required
                                          value={reviewTitle}
                                          onChange={(e) =>
                                            setReviewTitle(e.target.value)
                                          }
                                        />
                                      </p>
                                      <p className="comment-form-comment">
                                        <label htmlFor="comment">
                                          Your review&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <textarea
                                          id="comment"
                                          name="comment"
                                          cols="45"
                                          rows="8"
                                          required
                                          value={reviewDescription}
                                          onChange={(e) =>
                                            setReviewDescription(e.target.value)
                                          }
                                        ></textarea>
                                      </p>
                                      <p className="comment-form-author">
                                        <label htmlFor="author">
                                          Name&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          id="author"
                                          name="author"
                                          type="text"
                                          size="30"
                                          required
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                        />
                                      </p>
                                      <p className="comment-form-email">
                                        <label htmlFor="email">
                                          Email&nbsp;
                                          <span className="required">*</span>
                                        </label>
                                        <input
                                          id="email"
                                          name="email"
                                          type="email"
                                          size="30"
                                          required
                                          value={email}
                                          onChange={(e) =>
                                            setEmail(e.target.value)
                                          }
                                        />
                                      </p>
                                      <p className="comment-form-cookies-consent">
                                        <input
                                          id="wp-comment-cookies-consent"
                                          name="wp-comment-cookies-consent"
                                          type="checkbox"
                                          value="yes"
                                        />
                                        <label htmlFor="wp-comment-cookies-consent">
                                          Save my name, email, and website in
                                          this browser for the next time I
                                          comment.
                                        </label>
                                      </p>
                                      <p className="form-submit">
                                        <input
                                          name="submit"
                                          type="submit"
                                          id="submit"
                                          className="submit"
                                          value="Submit"
                                        />
                                      </p>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            )}


                            <div className="clear"></div>
                          </div>
                        </div>
                      </div>

                      <section className="related products">
                        <h2>Related products</h2>
                        <ul className="products columns-4">
                          {products.map((product) => (
                            <li
                              key={product.id}
                              className="product type-product post-407 status-publish instock product_cat-wedding-rings product_tag-catalog product_tag-wedding has-post-thumbnail sale shipping-taxable purchasable product-type-simple"
                            >
                              <div className="eltdf-pl-inner">
                                <div className="eltdf-pl-image">
                                  <img
                                    width={300}
                                    height={300}
                                    src={`${BASE_IMAGE_URL}/${product.primary_image}`}
                                    className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail product-resize-img"
                                    alt={product.product_name}
                                    decoding="async"
                                    loading="lazy"
                                  />
                                </div>
                                <div className="eltdf-pl-add-to-cart">
                                  <Link
                                    rel="nofollow"
                                    to="#"
                                    data-quantity={1}
                                    data-product_id={product.id}
                                    data-product_sku={product.sku}
                                    className="add_to_cart_button ajax_add_to_cart eltdf-button"
                                    onClick={(e) => { e.preventDefault(); handleSubmit(product.id); }}
                                  >
                                    Add to cart
                                    <span className="eltdf-add-to-cart-icon icon-arrows-circle-plus" />
                                  </Link>
                                </div>
                                <Link
                                  to="#"
                                  className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                                />
                              </div>
                              <div className="eltdf-pl-text-wrapper">
                                <h5 className="eltdf-product-list-title">
                                  <Link to={`/product-details/${product.product_slug}`}onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                    {product.product_name}
                                  </Link>
                                </h5>

                                <span className="price">
                                  <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span className="woocommerce-Price-currencySymbol">
                                        $
                                      </span>
                                      {product.base_price}
                                    </bdi>
                                  </span>
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
