import axios from 'axios';
import { BASE_URL } from '../../Components/config';

export const addToCart = async (productId,token,navigate) => {
    try {
      if (!token) {
        localStorage.setItem('redirectAfterLogin', window.location.pathname);
        navigate('/login');
        return;
      }
      const response = await axios.post(
        `${BASE_URL}/add-to-cart`,
        {
          product_id: productId,
          quantity: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error adding product to cart:', error);
      throw error;
    }
  };