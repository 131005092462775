import React,{useState, useEffect} from 'react'
import { BASE_IMAGE_URL, BASE_URL} from "./config";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { handleLogout } from '../Api/Auth';

const AccountSideBar = () => {
    const [userData, setUserData] = useState(null);
    const token = localStorage.getItem('token');
    useEffect(() => {
        const fetchUserData = async () => {
          try {
            const response = await axios.get(`${BASE_URL}/profile-image-get`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                  },
            });
            if (response.data.success) {
              setUserData(response.data.data.user);
            } else {
              console.error('Failed to fetch user data:', response.data.message);
            }
          } catch (error) {
            console.error('Error fetching user data', error);
          }
        };
    
        fetchUserData();
      }, []);
     
    
    const profileImage = userData?.image
    ? `${BASE_IMAGE_URL}/${userData.image}`
    : '../wp-content/uploads/2017/08/nouser.png';
  return (
    <div className="eltdf-page-content-holder eltdf-grid-col-2">
    <div className="profile-image-section">
            <img
              // src={userData.image ? `${BASE_IMAGE_URL}/${userData.image}` : "../wp-content/uploads/2017/08/nouser.png"}
              src={profileImage}
              alt="Profile"
          />
     </div>
      <div className="widget woocommerce widget_layered_nav woocommerce-widget-layered-nav">
        <ul
          className="woocommerce-widget-layered-nav-list"
          style={{ lineHeight: "3" }}
        >
          <li className="woocommerce-widget-layered-nav-list__item wc-layered-nav-term ">
            <Link rel="nofollow" to="/setting">
              <i
                className="fa-solid fa-gear"
                style={{ marginRight: "10px" }}
              ></i>
              Setting
            </Link>
          </li>
          <li className="woocommerce-widget-layered-nav-list__item wc-layered-nav-term ">
            <Link rel="nofollow" to="/my-order">
              <i
                className="fa-solid fa-bag-shopping"
                style={{ marginRight: "10px" }}
              ></i>
              Your order
            </Link>
          </li>

          <li className="woocommerce-widget-layered-nav-list__item wc-layered-nav-term ">
            <Link rel="nofollow" to="/login" onClick={handleLogout} >
              <i className="fa-solid fa-arrow-right-from-bracket" style={{marginRight:"10px", marginTop: "20px", }}> </i>
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AccountSideBar