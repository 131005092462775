import React, { useEffect, useState } from "react";

import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "./config";
import { ClipLoader } from "react-spinners";
import DocumentTitle from "./DocumentTitle";
import AccountSideBar from "./AccountSideBar";
const MyOrder = () => {
  DocumentTitle("ORDER | SYNTH - SOUNDS OF STREETWEAR");
  const token = localStorage.getItem("token");
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const page = queryParams.get("page");
    if (page) {
      setCurrentPage(Number(page));
    }
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/order-list?page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setOrders(response.data.data.order.data);
          setPagination(response.data.data.order);
        } else {
          console.error("Failed to fetch orders:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [currentPage, token]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      navigate(`/my-order?page=${pageNumber}`);
    }
  };

  return (
    <>
      <div className="page-template-default page page-id-424 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-account woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />
            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div
                  className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment"
                  style={{ height: 120, backgroundColor: "#fbf9f9" }}
                >
                  <div className="eltdf-title-holder" style={{ height: 120 }}>
                    <div className="eltdf-container clearfix">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title">
                              <span>My Order</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/">Home</Link>
                                  <span className="eltdf-delimiter">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current">
                                    My Order
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="eltdf-container eltdf-default-page-template">
                  <div className="eltdf-container-inner clearfix">
                    <div className="eltdf-grid-row">
                      <AccountSideBar />

                      <div className="eltdf-grid-col-10">
                        {loading ? (
                          <div style={{ textAlign: "center", marginTop: "50px" }} >
                            <ClipLoader  color={"#000"} loading={loading}  size={50} />
                          </div>
                        ) : (
                          <div className="table-container">
                            <table className="woocommerce-orders-table woocommerce-MyAccount-orders shop_table shop_table_responsive my_account_orders account-orders-table">
                              <thead>
                                <tr>
                                  <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-number">
                                    <span className="nobr">Order</span>
                                  </th>
                                  <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-date">
                                    <span className="nobr">Date</span>
                                  </th>
                                  <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-status">
                                    <span className="nobr">Status</span>
                                  </th>
                                  <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-total">
                                    <span className="nobr">Total</span>
                                  </th>
                                  <th className="woocommerce-orders-table__header woocommerce-orders-table__header-order-actions">
                                    <span className="nobr">Actions</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.length === 0 ? (
                                  <tr>
                                    <td
                                      colSpan="5"
                                      style={{ textAlign: "center" }}
                                    >
                                      No data available
                                    </td>
                                  </tr>
                                ) : (
                                  orders.map((order) => (
                                    <tr
                                      key={order.id}
                                      className={`woocommerce-orders-table__row woocommerce-orders-table__row--status-${order.status.toLowerCase()} order`}
                                    >
                                      <td
                                        className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-number"
                                        data-title="Order"
                                      >
                                        <a
                                          href={`/order-invoice/${order.order_number}`}
                                          className="bold-order-number"
                                        >
                                          {order.order_number}
                                        </a>
                                      </td>
                                      <td
                                        className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-date"
                                        data-title="Date"
                                      >
                                        <time
                                          dateTime={new Date(
                                            order.order_date
                                          ).toISOString()}
                                        >
                                          {new Date(
                                            order.order_date
                                          ).toLocaleDateString()}
                                        </time>
                                      </td>
                                      <td
                                        className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-status"
                                        data-title="Status"
                                      >
                                        {order.status}
                                      </td>
                                      <td
                                        className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-total"
                                        data-title="Total"
                                      >
                                        <span className="woocommerce-Price-amount amount">
                                          ₹{order.total_amount}
                                        </span>
                                      </td>
                                      <td
                                        className="woocommerce-orders-table__cell woocommerce-orders-table__cell-order-actions"
                                        data-title="Actions"
                                      >
                                        <a
                                          href={`/order-invoice/${order.order_number}`}
                                          className="button"
                                        >
                                          View
                                        </a>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                            <div className="eltdf-woo-pagination-holder">
                              <div className="eltdf-woo-pagination-inner">
                                <nav className="woocommerce-pagination">
                                  {pagination.links &&
                                  pagination.links.length > 0 ? (
                                    <ul className="page-numbers">
                                      {pagination.current_page > 1 &&
                                      pagination.prev_page_url ? (
                                        <li>
                                          <a
                                            className="page-numbers"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              const pageNumber = new URL(
                                                pagination.prev_page_url
                                              ).searchParams.get("page");
                                              handlePageChange(
                                                Number(pageNumber)
                                              );
                                            }}
                                            href={pagination.prev_page_url}
                                          >
                                            <i
                                              className="fa-solid fa-arrow-left-long"
                                              style={{ marginTop: "12px" }}
                                            ></i>
                                          </a>
                                        </li>
                                      ) : null}
                                      {(() => {
                                        const totalPages = pagination.last_page;
                                        const currentPage =
                                          pagination.current_page;
                                        const pageLinks = [];
                                        const startPage = Math.max(
                                          1,
                                          currentPage - 2
                                        );
                                        for (
                                          let i = startPage;
                                          i <= currentPage;
                                          i++
                                        ) {
                                          pageLinks.push(
                                            <li key={i}>
                                              <a
                                                className={`page-numbers ${
                                                  i === currentPage
                                                    ? "current"
                                                    : ""
                                                }`}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handlePageChange(i);
                                                }}
                                                href={`?page=${i}`}
                                              >
                                                {i}
                                              </a>
                                            </li>
                                          );
                                        }
                                        if (currentPage + 1 < totalPages - 1) {
                                          pageLinks.push(
                                            <li key="end-dots" className="dots">
                                              <span>...</span>
                                            </li>
                                          );
                                        }
                                        if (totalPages > currentPage) {
                                          for (
                                            let i = Math.max(
                                              currentPage + 1,
                                              totalPages - 1
                                            );
                                            i <= totalPages;
                                            i++
                                          ) {
                                            pageLinks.push(
                                              <li key={i}>
                                                <a
                                                  className="page-numbers"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handlePageChange(i);
                                                  }}
                                                  href={`?page=${i}`}
                                                >
                                                  {i}
                                                </a>
                                              </li>
                                            );
                                          }
                                        }
                                        return pageLinks;
                                      })()}
                                      {pagination.next_page_url ? (
                                        <li>
                                          <a
                                            className="page-numbers"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              const pageNumber = new URL(
                                                pagination.next_page_url
                                              ).searchParams.get("page");
                                              handlePageChange(
                                                Number(pageNumber)
                                              );
                                            }}
                                            href={pagination.next_page_url}
                                          >
                                            <i
                                              className="fa-solid fa-arrow-right-long"
                                              style={{ marginTop: "12px" }}
                                            ></i>
                                          </a>
                                        </li>
                                      ) : null}
                                    </ul>
                                  ) : (
                                    <ul className="page-numbers">
                                      <li>No pages available</li>
                                    </ul>
                                  )}
                                </nav>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrder;
