import React, { useEffect, useState } from "react";
import { BASE_IMAGE_URL } from "../Components/config";
import { Link, useLocation } from 'react-router-dom';
import { handleLogout } from "../Api/Auth";
import { fetchCartList } from "../Api/CartApi/CartListApi";
import { fetchCategories } from "../Api/CategoryApi";
import { deleteFromCart } from "../Api/CartApi/DeletCartApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Header = () => {
  const token = localStorage.getItem('token');
 
  
  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;
 
  
  useEffect(() => {
    const getCarts = async () => {
      if (token) {
        try {
          const cart = await fetchCartList(token);
          setCartItems(cart);
          setCartCount(cart.length);
        } catch (error) {
          console.error("Error fetching cart:", error);
        }
      }
    };

    getCarts();
  }, [token]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const categoryData = await fetchCategories(token);
        setCategories(categoryData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategory();
  }, [token]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const handleDeleteToCart = async (cartId) => {
    try {
      const response = await deleteFromCart(cartId, token);
      if (response.success) {
        toast.success("Product successfully deleted in cart", {
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("There was an error deleting your product.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("There was an error deleting your product.", {
        autoClose: 3000,
      });
    }
  };
 
  return (
    <>
      <header className="eltdf-page-header full-width-bg-black">
        <div className="eltdf-menu-area">
         
          <div className="eltdf-vertical-align-containers">
            <div className="eltdf-position-left">
              <div className="eltdf-position-left-inner">
                <div className="eltdf-logo-wrapper">
                  <a href="/" style={{ height: 81 }}>
                    <img
                      className="eltdf-normal-logo"
                      src="../wp-content/uploads/2017/07/logo.jpg"
                      width={332}
                      height={300}
                      alt="logo"
                    />
                    <img
                      className="eltdf-dark-logo"
                      src="../wp-content/uploads/2017/07/logo.jpg"
                      width={332}
                      height={83}
                      alt="dark logo"
                    />
                    <img
                      className="eltdf-light-logo"
                      src="../wp-content/uploads/2017/07/logo.jpg"
                      width={332}
                      height={83}
                      alt="light logo"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="eltdf-position-right">
              <div className="eltdf-position-right-inner">
                <nav className="eltdf-main-menu eltdf-drop-down eltdf-default-nav">
                  <ul id="menu-main-menu" className="clearfix">
                    <li
                      id="nav-menu-item-491"
                      className={`menu-item menu-item-type-custom menu-item-object-custom ${ isActive('/') ? 'man' : '' }`}
                    >
                      <a href="/" className={`${ isActive('/') ? 'man' : '' }`}>
                        <span className="item_outer">
                          <span className="item_text">Home</span>
                        </span>
                      </a>
                    </li>
                    <li id="nav-menu-item-492" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has_sub narrow">
                      <Link>
                        <span className="item_outer">
                          <span className="item_text">Categeory</span>
                        </span>
                      </Link>
                      <div className="second ">
                        <div className="inner">
                          <ul>
                            {categories.map((category) => (
                              <li className="eltdf-bl-item clearfix list-color" key={category.id}>
                                <a href={`/shop/cat_slug=${category.slug}`} title={category.title}>
                                  {category.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li id="nav-menu-item-1372" className={`menu-item menu-item-type-custom menu-item-object-custom ${ isActive('/shop') ? 'man' : ''}`} >
                      <a href="/shop">
                        <span className="item_outer">
                          <span className="item_text">Shop</span>
                        </span>
                      </a>
                    </li>

                    <li
                      id="nav-menu-item-492"
                      className={`menu-item menu-item-type-custom menu-item-object-custom ${isActive('/about-us') ? 'man' : '' }`} >
                      <a href="/about-us">
                        <span className="item_outer">
                          <span className="item_text">About US</span>
                        </span>
                      </a>
                    </li>
                    <li
                      id="nav-menu-item-492"
                      className={`menu-item menu-item-type-custom menu-item-object-custom ${ isActive('/contact-us') ? 'man' : '' }`} >
                      <a href="/contact-us">
                        <span className="item_outer">
                          <span className="item_text">Contact US</span>
                         
                        </span>
                      </a>
                    </li>

                    {!isLoggedIn && (
                      <li
                        id="nav-menu-item-492"
                        className={`menu-item menu-item-type-custom menu-item-object-custom ${ isActive('/login') ? 'eltdf-active-item' : '' }`} >
                        <Link to="/login">
                          <span className="item_outer">
                            <span className="item_text">Sign In</span>
                          </span>
                        </Link>
                      </li>
                    )}

                    {isLoggedIn && (
                      <li
                        id="nav-menu-item-492"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has_sub narrow"
                      >
                        <Link to="#">
                          <span className="item_outer">
                            <span className="item_text">
                              <i className="fa-regular fa-user" style={{ fontSize: "20px" }} ></i>
                            </span>
                            <i aria-hidden="true" className="eltdf-menu-arrow lnr lnr-arrow-right" />
                          </span>
                        </Link>
                        <div className="second">
                          <div className="inner ">
                            <ul className="list-color" style={{ width: "120px" }}>
                              <li
                                id="nav-menu-item-1328"
                                className="menu-item menu-item-type-post_type menu-item-object-page"
                              >
                                <Link to="/setting">
                                  <span>
                                    <span className="item_text">Setting</span>
                                  </span>
                                </Link>
                              </li>
                              <li
                                id="nav-menu-item-1327"
                                className="menu-item menu-item-type-post_type menu-item-object-page"
                              >
                                <Link to="/my-order">
                                  <span>
                                    <span className="item_text">MyOrder</span>
                                  </span>
                                </Link>
                              </li>
                              <li
                                id="nav-menu-item-1330"
                                className="menu-item menu-item-type-post_type menu-item-object-page"
                              >
                                <Link to="/login" onClick={handleLogout}>
                                  <span>
                                    <span className="item_text">Logout</span>
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </nav>
                <div className="eltdf-main-menu-widget-area">
                  <div className="eltdf-main-menu-widget-area-inner">
                    <div className="eltdf-shopping-cart-holder eltdf-cart-info-is-disabled">
                      <div className="eltdf-shopping-cart-inner">
                        <Link className="eltdf-header-cart" to="/cart">
                          <img
                            src="../wp-content/uploads/2017/08/ecommerce-bag.png"
                            alt="not found"
                            style={{ width: "30px", height: "30px",filter: "invert(3)" }}
                          />
                          <span  className="eltdf-cart-count" style={{ marginTop: "3px",filter: "invert(1)"  }}  >
                            {cartCount}
                          </span>
                          <span className="eltdf-cart-info">
                            <span className="eltdf-cart-info-total">
                              (
                              <span className="woocommerce-Price-amount amount">
                                ₹
                                0
                              </span>
                              )
                            </span>
                          </span>
                        </Link>

                        <div className="eltdf-shopping-cart-dropdown">
                          {cartItems.length > 0 ? (
                            <ul>
                              {cartItems.map((product) => (
                                <li key={product.id}>
                                  <div className="eltdf-item-image-holder">
                                    <a href={`#/${product.product_slug}`}>
                                      <img
                                        width="300"
                                        height="300"
                                        src={`${BASE_IMAGE_URL}/${product.product.primary_image}`}
                                        className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                        alt={product.product_name}
                                      />
                                    </a>
                                  </div>
                                  <div className="eltdf-item-info-holder">
                                    <h5 className="eltdf-product-title">
                                      <a href={`#/${product.product_slug}`}>
                                        {product.product.product_name}
                                      </a>
                                    </h5>
                                    <span className="eltdf-quantity">
                                      {product.quantity}
                                    </span>
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi>
                                        ₹
                                        {product.product.base_price}
                                      </bdi>
                                    </span>
                                    <Link
                                      to="#"
                                      className="remove"
                                      title="Remove this item"
                                    >
                                      <span
                                        className="icon-arrows-remove"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDeleteToCart(product.id);
                                        }}
                                      ></span>
                                    </Link>
                                  </div>
                                </li>
                              ))}
                              <div className="eltdf-cart-bottom">
                                <div className="eltdf-subtotal-holder clearfix">
                                  <span className="eltdf-total">TOTAL:</span>
                                  <span className="eltdf-total-amount">
                                    <span className="woocommerce-Price-amount amount">
                                     
                                      ₹
                                      {cartItems
                                        .reduce(
                                          (total, item) =>
                                            total +
                                            item.product.base_price *
                                              item.quantity,
                                          0
                                        )
                                        .toFixed(2)}
                                    </span>
                                  </span>
                                </div>
                                <div className="eltdf-btn-holder clearfix">
                                  <Link
                                    to="/cart"
                                    className="eltdf-view-cart"
                                    data-title="VIEW CART"
                                  >
                                    <span>VIEW CART</span>
                                  </Link>
                                </div>
                                <div className="eltdf-btn-holder clearfix">
                                  <Link
                                    to="/check-out"
                                    className="eltdf-view-cart "
                                    data-title="CHECKOUT"
                                  >
                                    <span>CHECKOUT</span>
                                  </Link>
                                </div>
                              </div>
                            </ul>
                          ) : (
                            <ul>
                              <li className="eltdf-empty-cart">
                                No products in the cart.
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>

                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="eltdf-sticky-header">
          
          <div className="eltdf-sticky-holder">
            <div className=" eltdf-vertical-align-containers">
              <div className="eltdf-position-left">
                <div className="eltdf-position-left-inner">
                  <div className="eltdf-logo-wrapper">
                    <Link to="#" style={{ height: 81 }}>
                      <img
                        className="eltdf-normal-logo"
                        src="../wp-content/uploads/2017/07/logo.jpg"
                        width={332}
                        height={83}
                        alt="logo"
                      />
                      <img
                        className="eltdf-dark-logo"
                        src="../wp-content/uploads/2017/07/logo.jpg"
                        width={332}
                        height={83}
                        alt="dark logo"
                      />
                      <img
                        className="eltdf-light-logo"
                        src="../wp-content/uploads/2017/07/logo.jpg"
                        width={332}
                        height={83}
                        alt="light logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="eltdf-mobile-header">
        <div className="eltdf-mobile-header-inner">
          <div className="eltdf-mobile-header-holder full-width-bg-black">
            <div className="eltdf-grid">
              {/* <div className="eltdf-vertical-align-containers"> */}
                <div className="eltdf-vertical-align-containers">
                  <div className="eltdf-mobile-menu-opener"></div>
                  <div className="eltdf-position-center">
                    <div className="eltdf-position-center-inner">
                      <div className="eltdf-mobile-logo-wrapper">
                        <Link to="/" style={{ height: 41 }}>
                          <img
                            src="../wp-content/uploads/2017/07/logo.jpg"
                            width={332}
                            height={83}
                            alt="mobile logo"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="eltdf-position-right">
                    <div className="eltdf-position-right-inner"></div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
          <nav className="eltdf-mobile-nav">
            <div className="eltdf-grid">
              <ul id="menu-main-menu-2">
                <li
                  id="mobile-menu-item-491"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor menu-item-has-children eltdf-active-item has_sub"
                >
                  <a href="/" className="current  eltdf-mobile-no-link">
                    <span>Home</span>
                  </a>
                  <span className="mobile_arrow">
                    <i className="eltdf-sub-arrow fa fa-angle-right" />
                    <i className="fa fa-angle-down" />
                  </span>
                </li>

                <li
                  id="mobile-menu-item-1372"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub"
                >
                  <a href="/shop" className="eltdf-mobile-no-link">
                    <span>Shop</span>
                  </a>
                  <span className="mobile_arrow">
                    <i className="eltdf-sub-arrow fa fa-angle-right" />
                    <i className="fa fa-angle-down" />
                  </span>
                  
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
