import axios from "axios";
import { BASE_URL } from "../Components/config";


export const fetchBanner = async (token) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/banner-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data.banners;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };
  