import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentTitle from './DocumentTitle';
const ResetPassword = () => {
  DocumentTitle("RESET PASSWORD | SYNTH - SOUNDS OF STREETWEAR");
  const { token } = useParams();
  

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
  const navigate = useNavigate();
  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      setError("Email not found in localStorage.");
    }
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { password: '', confirmPassword: '' };

    if (!password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (password.length < 8) {
      newErrors.password = 'New password must be at least 8 characters long';
      isValid = false;
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm Password is Required';
      isValid = false;
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      try {
        const response = await axios.post(`${BASE_URL}/submit-reset-password-form`, {
          token,
          email,
          password,
          password_confirmation: confirmPassword
        
        });
  
        if (response.data.success) {
            toast.success(response.data.message, {
              autoClose: 3000,
            });
          setSuccess(response.data.message);
          setError("");
          navigate('/login')
        } else {
          setSuccess("");
          toast.error(response.data.message, {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.log(error);
      } 
    } else {
      console.log('Form is invalid. Errors: ', errors);
    }
  };

  return (
    <>
      <div className="eltdf-header-standard">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />
            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div
                  className="eltdf-title eltdf-standard-type eltdf-content-left-alignment"
                  style={{ height: 120, backgroundColor: "#fbf9f9" }}
                >
                  <div className="eltdf-title-holder" style={{ height: 120 }}>
                    <div className="eltdf-container clearfix">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title">
                              <span>Reset Password</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/">Home</Link>
                                  <span className="eltdf-delimiter">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current">
                                    Reset Password
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eltdf-container eltdf-default-page-template">
                  <div className="eltdf-container-inner clearfix">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper" />
                          <div className="eltdf-woocommerce-account-login-form">
                            <h2 style={{ textAlign: "center" }}>
                              Reset Password
                            </h2>
                            <form  onSubmit={handleSubmit}  className="wpcf7-form init demo" noValidate="novalidate" data-status="init">
                              <div>
                                <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                <div className="grid-col-12">
                                     <input type="email" size={40}  placeholder="Email" value={email} readOnly className="wpcf7-form-control wpcf7-text"
                                      />
                                      
                                </div>
                                  <div className="eltdf-grid-col-12">
                                    <span className="wpcf7-form-control-wrap">
                                      <input
                                        type="password"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                        placeholder="New Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                      />
                                     {errors.password && (
                                        <div className="error-message">
                                          {errors.password}
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                  <div className="eltdf-grid-col-12">
                                    <span className="wpcf7-form-control-wrap">
                                      <input
                                        type="password"
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                      />
                                      {errors.confirmPassword && (
                                        <div className="error-message">
                                          {errors.confirmPassword}
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <button type="submit" className="woocommerce-button button woocommerce-form-login__submit wp-element-button">
                                Reset Password
                              </button>
                            </form>
                            {error && <p className="error-message">{error}</p>}
                            {success && ( <p className="success-message">{success}</p> )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
