import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import DocumentTitle from './DocumentTitle';
const AboutUs = () => {
  DocumentTitle("ABOUT US | SYNTH - SOUNDS OF STREETWEAR");
  return (
    <>
      <div className="page-template page-template-full-width page-template-full-width-php page page-id-530 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-header-standard-right-position eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />
            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div className="eltdf-full-width">
                  <div className="eltdf-full-width-inner">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <section className="wpb-content-wrapper">
                          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="eltdf-elements-holder eltdf-two-columns eltdf-responsive-mode-768">
                                    <div  className="eltdf-eh-item">
                                        <div className="eltdf-eh-item-background-holder">
                                          <div  className="eltdf-eh-item-background" style={{  backgroundImage:  "url(../wp-content/uploads/2017/07/her.jpg)",}} />
                                        </div>  
                                    </div>
                                    <div className="eltdf-eh-item">
                                      <div className="eltdf-eh-item-inner">
                                        <div className="eltdf-eh-item-content eltdf-eh-custom-827136"
                                          style={{padding: "24.5% 31% 26% 13%", }} >
                                          <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                              <h1>GET TO KNOW US -</h1>
                                            </div>
                                          </div>
                                          <div className="wpb_text_column wpb_content_element  vc_custom_1502287365390">
                                            <div className="wpb_wrapper">
                                              <h4>
                                                <span style={{ textTransform: "initial", }}>
                                                  Synth embraces a diverse and inclusive approach towards fashion, by incorporating elements of the streetwear culture our collection features versatile and androgynous pieces. At Synth we prioritise comfort and style.
                                                  By the Gen Z, for the Gen Z "
                                                </span>
                                              </h4>
                                            </div>
                                          </div>
                                          <Link itemProp="url" to="/shop" target="_self" className="about-btn eltdf-btn eltdf-btn-medium eltdf-btn-with-arrow eltdf-btn-custom-hover-bg eltdf-btn-custom-hover-color"
                                            style={{marginTop:'30px'}} >
                                            <span className="eltdf-btn-text">Explore Our Shop </span>
                                            <i className="fa-solid fa-arrow-right" style={{marginLeft:'10px'}}></i>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                  <div className="eltdf-elements-holder eltdf-two-columns eltdf-responsive-mode-768">
                                  <div className="eltdf-eh-item">
                                      <div className="eltdf-eh-item-inner">
                                        <div className="eltdf-eh-item-content eltdf-eh-custom-827136"
                                          style={{padding: "24.5% 31% 26% 13%"}}  >
                                          <div className="wpb_text_column wpb_content_element ">
                                            <div className="wpb_wrapper">
                                              <h1>Our Fashion</h1>
                                            </div>
                                          </div>
                                          <div className="wpb_text_column wpb_content_element  vc_custom_1502287365390">
                                            <div className="wpb_wrapper">
                                              <h4>
                                                <span style={{textTransform: "initial"}}>
                                                  Synth embraces a diverse and inclusive approach towards fashion, by incorporating elements of the streetwear culture, we feature androgynous pieces.
                                                </span>
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="eltdf-eh-item">
                                        <div className="eltdf-eh-item-background-holder">
                                          <div className="eltdf-eh-item-background" style={{backgroundImage:"url(../wp-content/uploads/2017/07/about-3.jpg)"}}/>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
