import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { BASE_URL, BASE_IMAGE_URL } from "./config";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import DocumentTitle from './DocumentTitle';
import { useReactToPrint } from "react-to-print";
import AccountSideBar from "./AccountSideBar";
const OrderInvoice = () => {
  DocumentTitle("INVOICE | SYNTH - SOUNDS OF STREETWEAR");
  const token = localStorage.getItem("token");
  const { orderNumber } = useParams();
  const [orderDetails, setOrderDetails] = useState();
  const [loading, setLoading] = useState(true);
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/order-detail/${orderNumber}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [token, orderNumber]);

  if (!orderDetails) {
    return (
      <div>
        <div className="loader-container">
          <ClipLoader size={50} />
        </div>
      </div>
    );
  }

  const subtotal = orderDetails.data.order.order_items.reduce(
    (sum, item) => sum + item.product_unitprice * item.product_qty,
    0
  );
  const grandTotal = subtotal;

 

  return (
    <>
      <div className="page-template-default page page-id-424 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-account woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />

            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div
                  className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment"
                  style={{ height: 120, backgroundColor: "#fbf9f9" }}
                >
                  <div className="eltdf-title-holder" style={{ height: 120 }}>
                    <div className="eltdf-container clearfix">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title">
                              <span> Order Invoice</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/">Home</Link>
                                  <span className="eltdf-delimiter">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current">
                                    Order Invoice
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="eltdf-container eltdf-default-page-template">
                  <div className="eltdf-container-inner clearfix">
                    <div className="eltdf-grid-row">
                     <AccountSideBar/>

                      <div className="eltdf-grid-col-10" ref={printRef}>
                     
                        <ul className="woocommerce-order-overview woocommerce-thankyou-order-details order_details no-bullets">
                            <li className="woocommerce-order-overview__order order">
                             <b> Order number: </b>  {orderDetails.data.order.order_number}
                            </li>
                            <li className="woocommerce-order-overview__date date">
                              <b>Date:</b> {new Date(  orderDetails.data.order.order_date).toLocaleDateString()}
                            </li>
                            <li className="woocommerce-order-overview__email email">
                             <b>Email: </b>{orderDetails.data.order.b_email}
                            </li>
                            <li className="woocommerce-order-overview__total total">
                              <b>Total: </b>
                                <span className="woocommerce-Price-amount amount">
                                  <bdi>
                                    <span className="woocommerce-Price-currencySymbol">
                                      { orderDetails.data.order.total_amount}
                                    </span>
                                  </bdi>
                                </span>
                            </li>
                            <li className="woocommerce-order-overview__payment-method method">
                            <b> Payment method: </b>
                              {orderDetails.data.order.payments.payment_method}
                            </li>
                            <li className="woocommerce-order-overview__payment-method method">
                            <b>Status: </b>{orderDetails.data.order.status}
                            </li>
                        </ul>
                        <section className="print-onlywoocommerce-order-details">
                          <h2 className="woocommerce-order-details__title">
                            Product details
                          </h2>
                          <div>
                            {loading ? (
                              <div className="loader-container">
                                <ClipLoader size={50} />
                              </div>
                            ) : (
                              <table
                                className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                                cellSpacing={0}
                              >
                                <thead>
                                  <tr>
                                    <th className="product-thumbnail">
                                      <span>Image</span>
                                    </th>
                                    <th>Product</th>
                                    <th>Amount</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {orderDetails.data.order.order_items.map(
                                    (item) => (
                                      <tr
                                        key={item.id}
                                        className="woocommerce-cart-form__cart-item cart_item"
                                      >
                                        <td className="product-thumbnail">
                                          <Link
                                            to={`/product-details/${item.products.product_slug}`}
                                          >
                                            <img
                                              width={70}
                                              height={70}
                                              src={`${BASE_IMAGE_URL}/${item.products.primary_image}`}
                                              alt={item.products.product_name}
                                              loading="lazy"
                                            />
                                          </Link>
                                        </td>
                                        <td className="product-name" data-title="Product">
                                          <a href={`/product-details/${item.products.product_slug}`}>{item.products.product_name}</a>
                                         </td>
                                        <td className="product-price">
                                          ₹{item.product_unitprice}
                                        </td>
                                        <td className="product-quantity">
                                          {item.product_qty}
                                        </td>
                                        <td className="product-total">
                                          ₹
                                          {item.product_unitprice *
                                            item.product_qty}
                                        </td>
                                       
                                      </tr>
                                    )
                                  )}
                                  <tr className="cart-subtotal">
                                    <th
                                      colSpan="4"
                                      style={{ textAlign: "left" }}
                                    >
                                      Subtotal
                                    </th>
                                    <td>₹{subtotal.toFixed(2)}</td>
                                  </tr>
                                  <tr className="order-total">
                                    <th
                                      colSpan="4"
                                      style={{ textAlign: "left" }}
                                    >
                                      Grand Total
                                    </th>
                                    <td>₹{grandTotal.toFixed(2)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </div>
                        </section>

                        <section className="print-only woocommerce-customer-details">
                          <section
                            className="custom-section woocommerce-columns--2 woocommerce-columns--addresses col2-set addresses "
                            style={{ display: "flex", marginLeft: "20px" }}
                          >
                           
                            <div
                              className="custom-billing-column woocommerce-column--1 woocommerce-column--billing-address col-1"
                              style={{ flex: 1, paddingRight: "10px" }}
                            >
                              <h2 className="woocommerce-column__title">
                                Billing address
                              </h2>
                             
                              <address>
                                <span>{orderDetails.data.order.b_address_line1}, {orderDetails.data.order.b_address_line2}</span>
                                <br />
                                <span>{orderDetails.data.order.b_city}, {orderDetails.data.order.b_state}</span>
                                <br />
                                <span>{orderDetails.data.order.b_country}, {orderDetails.data.order.b_pincode}</span>
                                 <br />
                                <span>{orderDetails.data.order.b_email},{orderDetails.data.order.b_contact}</span>
                                <br />
 
                              </address>

                            </div>
                            <div
                              className="woocommerce-column woocommerce-column--2 woocommerce-column--shipping-address col-2"
                              style={{ flex: 1, paddingRight: "10px" }}
                            >
                              <h2 className="woocommerce-column__title">
                                Shipping address
                              </h2>
                              <address>
                                <span>{orderDetails.data.order.b_address_line1}, {orderDetails.data.order.b_address_line2}</span>
                                <br />
                                <span>{orderDetails.data.order.b_city}, {orderDetails.data.order.b_state}</span>
                                <br />
                                <span>{orderDetails.data.order.b_country}, {orderDetails.data.order.b_pincode}</span>
                                 <br />
                                <span>{orderDetails.data.order.b_email},{orderDetails.data.order.b_contact}</span>
                                <br />
 
                              </address>
                            </div>
                          </section>
                        </section>

                        <button
                          className="download-invoice-button"
                          onClick={handlePrint}
                        >
                          Print Invoice
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInvoice;
