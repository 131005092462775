
const Url="https://admin.sythn.krishnasoftweb.com/api";
const ImgUrl="https://admin.sythn.krishnasoftweb.com";
const reset_password_link = "https://sythn.krishnasoftweb.com/reset-password-link"

// const Url="http://192.168.29.136:8000/api";
// const ImgUrl="http://192.168.29.136:8000";
// const reset_password_link ="http://192.168.29.61:3000/reset-password-link";

const BASE_URL = Url;
const BASE_IMAGE_URL = ImgUrl;

export { BASE_URL, BASE_IMAGE_URL,reset_password_link, }



