import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

import { BASE_URL } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import DocumentTitle from './DocumentTitle';
import AccountSideBar from "./AccountSideBar";
const Setting = () => {

  DocumentTitle("SETTING | SYNTH - SOUNDS OF STREETWEAR");
  const token = localStorage.getItem('token');
  const [imageData, setImageData] = useState({ image: null });
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    image:''
  });

  const [message] = useState("");

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');
    const  storedPhone = localStorage.getItem('phone');
    if (storedName || storedEmail || storedPhone) {
      setUserData((prevState) => ({
        ...prevState,
        name: storedName || "",
        email: storedEmail || "",
        phone:storedPhone || "",
      }));
    }
  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleChanges = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    if (!formData.currentPassword) {
      newErrors.currentPassword = "Current password is required";
      isValid = false;
    }

    if (!formData.newPassword) {
      newErrors.newPassword = "New password is required";
      isValid = false;
    } else if (formData.newPassword.length < 8) {
      newErrors.newPassword = "New password must be at least 8 characters long";
      isValid = false;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm password does not match";
      isValid = false;
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const validateFormAccount = () => {
    let isValid = true;
    const newErrors = {
      name: "",
      email: "",
      phone: "",
    };

    if (!userData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!userData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userData.email)) {
        newErrors.email = "Please enter a valid email address";
        isValid = false;
      }
    }
    if (!userData.phone) {
      newErrors.phone = "Phone Number is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmits = async (event) => {
    event.preventDefault();
    if (!validateFormAccount()) {
      return;
    }
    try {
      const response = await axios.post(
        `${BASE_URL}/profile-update`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      
      const updatedUserData = response.data.data.user;
      localStorage.setItem('name', updatedUserData.name);
      localStorage.setItem('phone', updatedUserData.phone);
      setUserData({
        name: updatedUserData.name,
        email: updatedUserData.email, 
        phone: updatedUserData.phone
      });
      toast.success("Profile updated successfully", response.data,{
        autoClose: 2000,
      });
    
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while updating the profile");
      toast.error("An error occurred while updating the profile",{
        autoClose: 3000,
      })
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/change-password`,
        {
          current_password: formData.currentPassword,
          new_password: formData.newPassword,
          confirm_password: formData.confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message, {
        autoClose: 3000,
      });

      setFormData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setErrors({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      toast.error("Error changing password", {
        autoClose: 3000,
      });
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    setImageData({ image: file });
    if (file) {
      setErrors(prevErrors => ({ ...prevErrors, image: '' }));
    }
  };
  

  const handleProfile = async (e) => {
    e.preventDefault();
  
    if (!imageData.image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "Image field is required.",
      }));
      return;
    }
  
    const imgData = new FormData();
    imgData.append("image", imageData.image);
  
    try {
      const response = await axios.post(
        `${BASE_URL}/profile-image-update`,
        imgData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
   
      if (response.data.success) {
        toast.success(response.data.message);
  
        setUserData((prevUserData) => ({
          ...prevUserData,
          image: response.data.data.user.image, 
        }));
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error Occurred:", error);
      toast.error("An error occurred while updating the profile image.");
    }
  };
  
  return (
    <>
      <div className="page-template-default page page-id-424 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-account woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />
          
            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div
                  className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment"
                  style={{ height: 120, backgroundColor: "#fbf9f9" }}
                  data-height={120}
                >
                  <div className="eltdf-title-holder" style={{ height: 120 }}>
                    <div className="eltdf-container clearfix">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title">
                              <span>Setting</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <Link to="/">Home</Link>
                                  <span className="eltdf-delimiter">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current">Setting</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="eltdf-container eltdf-default-page-template">
                  <div className="eltdf-container-inner clearfix">
                    
                    <div className="eltdf-grid-row">
                      <AccountSideBar/>
                      <div className="eltdf-page-content-holder eltdf-grid-col-10">
                        <div className="eltdf-grid-row">
                          <div className="eltdf-page-content-holder eltdf-grid-col-12">
                            <div className="woocommerce">
                              <div className="woocommerce-notices-wrapper" />
                              <div className="eltdf-woocommerce-account-login-form">
                                <h2>Profile Imges Update</h2>
                                <form
                                  className="wpcf7-form init demo w-full"
                                  onSubmit={handleProfile}
                                >
                                  <div>
                                    <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                      <div className="eltdf-grid-col-12">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="profile-image"
                                        >
                                          <label htmlFor="profile-image">
                                            Profile Image
                                          </label>
                                          <input
                                            type="file"
                                            name="name"
                                            size={40}
                                            className="wpcf7-form-control wpcf7-text"
                                            placeholder="Name"
                                            onChange={handleChange}
                                          />
                                          {errors.image && (
                                              <div className="error-message">
                                                {errors.image}
                                              </div>
                                              )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                 
                                  <div
                                    className="wpcf7-response-output"
                                    aria-hidden="true"
                                  />
                                  <button
                                    type="submit"
                                    className="woocommerce-button button woocommerce-form-login__submit wp-element-button"
                                  >
                                    Upload Imges
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div className="eltdf-page-content-holder eltdf-grid-col-12 setting_forms" >
                            <div className="woocommerce">
                              <div className="woocommerce-notices-wrapper" />
                              <div className="eltdf-woocommerce-account-login-form">
                                <h2> Account Setting</h2>
                                <form
                                  onSubmit={handleSubmits}
                                  className="wpcf7-form init demo w-full"
                                  noValidate
                                >
                                  <div>
                                    <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                      <div className="eltdf-grid-col-12">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="your-name"
                                        >
                                          <label htmlFor="name">Name</label>
                                          <input
                                            type="text"
                                            name="name"
                                            size={40}
                                            className="wpcf7-form-control wpcf7-text"
                                            placeholder="Name"
                                            required
                                            value={userData.name}
                                            onChange={handleChanges}
                                          />
                                          {errors.name && (
                                            <div className="error-message">
                                              {errors.name}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                      <div className="eltdf-grid-col-12">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="your-email"
                                        >
                                          <label htmlFor="email">Email</label>
                                          <input
                                            type="email"
                                            name="email"
                                            size={40}
                                            className="wpcf7-form-control wpcf7-text wpcf7-email"
                                            placeholder="Email"
                                            readOnly
                                            value={userData.email}
                                            onChange={handleChanges}
                                          />
                                          {errors.email && (
                                            <div className="error-message">
                                              {errors.email}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                      <div className="eltdf-grid-col-12">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="your-phone"
                                        >
                                          <label htmlFor="phone">Phone</label>
                                          <input
                                            type="text"
                                            name="phone"
                                            size={40}
                                            className="wpcf7-form-control wpcf7-text"
                                            placeholder="Phone Number"
                                            required
                                            value={userData.phone}
                                            onChange={handleChanges}
                                          />
                                          {errors.phone && (
                                            <div className="error-message">
                                              {errors.phone}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="wpcf7-response-output"
                                    aria-hidden="true"
                                  />
                                  <button
                                    type="submit"
                                    className="woocommerce-button button woocommerce-form-login__submit wp-element-button"
                                  >
                                    Save Details
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div className="eltdf-page-content-holder eltdf-grid-col-12 ">
                            <div className="woocommerce">
                              <div className="woocommerce-notices-wrapper" />
                              <div className="eltdf-woocommerce-account-login-form">
                                <h2>Change Password</h2>
                                <form
                                  onSubmit={handleSubmit}
                                  className="wpcf7-form init demo"
                                  noValidate="novalidate"
                                  data-status="init"
                                >
                                  <div>
                                    <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                      <div className="eltdf-grid-col-12">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="your-name"
                                        >
                                          <label htmlFor="currentPassword">
                                            Current Password
                                          </label>
                                          <input
                                            type="password"
                                            name="currentPassword"
                                            size={100}
                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true"
                                            aria-invalid="false"
                                            placeholder="Current Password"
                                            value={formData.currentPassword}
                                            onChange={handleInputChange}
                                          />
                                          {errors.currentPassword && (
                                            <div className="error-message">
                                              {errors.currentPassword}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                      <div className="eltdf-grid-col-12">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="your-name"
                                        >
                                          <label htmlFor="newPassword">
                                            New Password
                                          </label>
                                          <input
                                            type="password"
                                            name="newPassword"
                                            size={100}
                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true"
                                            aria-invalid="false"
                                            placeholder="New Password"
                                            value={formData.newPassword}
                                            onChange={handleInputChange}
                                          />
                                          {errors.newPassword && (
                                            <div className="error-message">
                                              {errors.newPassword}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                      <div className="eltdf-grid-col-12">
                                        <span
                                          className="wpcf7-form-control-wrap"
                                          data-name="your-email"
                                        >
                                          <label htmlFor="confirmPassword">
                                            Confirm Password
                                          </label>
                                          <input
                                            type="password"
                                            name="confirmPassword"
                                            size={100}
                                            className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                                            aria-invalid="false"
                                            placeholder="Confirm Password"
                                            value={formData.confirmPassword}
                                            onChange={handleInputChange}
                                          />
                                          {errors.confirmPassword && (
                                            <div className="error-message">
                                              {errors.confirmPassword}
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="wpcf7-response-output"
                                    aria-hidden="true"
                                  >
                                    <div className="error-message">
                                      {message}
                                    </div>
                                  </div>
                                  <button
                                    type="submit"
                                    className="woocommerce-button button woocommerce-form-login__submit wp-element-button"
                                    name="savepassword"
                                    value="savepassword"
                                  >
                                    Change password
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
