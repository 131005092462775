import React, { useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { BASE_URL } from "./config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentTitle from './DocumentTitle';
const ContactUs = () => {
  DocumentTitle("CONTACT US | SYNTH - SOUNDS OF STREETWEAR");
  const [formData, setFormData] = useState({firstName: "",lastName: "", email: "", phone: "", message: "",});
  const [errors, setErrors] = useState({firstName: "",lastName: "", email: "",phone: "",message: "",});
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = { firstName: "", lastName: "", email: "", phone: "", message: "",};

    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
      isValid = false;
    }
    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
      isValid = false;
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = "Please enter a valid email address";
        isValid = false;
      }
    }
    if (!formData.phone) {
      newErrors.phone = "Phone Number is required";
      isValid = false;
    }
    if (!formData.message) {
      newErrors.message = "Message is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios.post(`${BASE_URL}/contact-us`, {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      });
      toast.success(response.data.message, {autoClose: 3000,});
      setFormData({firstName: "",lastName: "", email: "",phone: "",message:""});
    } catch (error) {
      toast.error("Failed to send message.", {autoClose: 1500, });
    }
  };
  return (
    <>
      <div className="page-template page-template-full-width page-template-full-width-php page page-id-1185 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />
            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div className="eltdf-full-width">
                  <div className="eltdf-full-width-inner">
                    <div  className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment"  style={{ height: 120, backgroundColor: "#fbf9f9" }}  data-height={120}>
                      <div  className="eltdf-title-holder"  style={{ height: 120 }}>
                        <div className="eltdf-container clearfix">
                          <div className="eltdf-container-inner">
                            <div className="eltdf-title-subtitle-holder">
                              <div className="eltdf-title-subtitle-holder-inner">
                                <h5 className="eltdf-page-title entry-title">
                                  <span>Contact Us</span>
                                </h5>
                                <div className="eltdf-breadcrumbs-holder">
                                  <div className="eltdf-breadcrumbs">
                                    <div className="eltdf-breadcrumbs-inner">
                                      <a href="/">Home</a>
                                      <span className="eltdf-delimiter">
                                        &nbsp; / &nbsp;
                                      </span>
                                      <span className="eltdf-current">
                                        Contact Us
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <section className="wpb-content-wrapper">
                          <div className="eltdf-row-grid-section"style={{ marginBottom: "30px", marginTop: "30px" }} >
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1503483162971">
                              <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-1 vc_col-lg-10">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element">
                                      <div className="wpb_wrapper">
                                        <h2 style={{ textAlign: "center" }}>
                                          Contact form
                                        </h2>
                                      </div>
                                    </div>
                                    <div className="vc_empty_space" style={{ height: 42 }}>
                                      <span className="vc_empty_space_inner" />
                                    </div>
                                    <div role="form" className="wpcf7">
                                      <form  onSubmit={handleSubmit}className="wpcf7-form init demo">
                                        <div>
                                          <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                            <div className="eltdf-grid-col-6">
                                              <span  className="wpcf7-form-control-wrap"  data-name="first-name">
                                                <input type="text"name="firstName" size={40}
                                                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                  aria-required="true"
                                                  aria-invalid="false"
                                                  placeholder="First Name"
                                                  value={formData.firstName}
                                                  onChange={handleChange}
                                                />
                                                {errors.firstName && (
                                                  <div className="error-message">
                                                    {errors.firstName}
                                                  </div>
                                                )}
                                              </span>
                                            </div>
                                            <div className="eltdf-grid-col-6">
                                              <span  className="wpcf7-form-control-wrap"  data-name="last-name"  >
                                                <input  type="text"   name="lastName"  size={40}
                                                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                  aria-required="true"
                                                  aria-invalid="false"
                                                  placeholder="Last Name"
                                                  value={formData.lastName}
                                                  onChange={handleChange}
                                                />
                                                {errors.lastName && (
                                                  <div className="error-message">
                                                    {errors.lastName}
                                                  </div>
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="eltdf-grid-row eltdf-grid-small-gutter">
                                            <div className="eltdf-grid-col-6">
                                              <span className="wpcf7-form-control-wrap" data-name="email">
                                                <input  type="email" name="email" size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                                                  aria-invalid="false"
                                                  placeholder="Email"
                                                  value={formData.email}
                                                  onChange={handleChange}
                                                />
                                                {errors.email && ( <div className="error-message"> {errors.email} </div> )}
                                              </span>
                                            </div>
                                            <div className="eltdf-grid-col-6">
                                              <span  className="wpcf7-form-control-wrap"  data-name="phone">
                                                <input  type="text"  name="phone" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                  aria-required="true"
                                                  aria-invalid="false"
                                                  placeholder="Phone"
                                                  value={formData.phone}
                                                  onChange={handleChange}
                                                />
                                                {errors.phone && (<div className="error-message">{errors.phone} </div> )}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="eltdf-grid-row">
                                            <div className="eltdf-grid-col-12">
                                              <span  className="wpcf7-form-control-wrap" data-name="message" >
                                                <textarea  name="message" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea"aria-invalid="false"
                                                  placeholder="Message"
                                                  value={formData.message}
                                                  onChange={handleChange}
                                                />
                                                {errors.message && ( <div className="error-message">{errors.message} </div> )}
                                              </span>
                                            </div>
                                          </div>
                                          <p style={{ textAlign: "center" }}>
                                            <input type="submit" value="Send Message"className="wpcf7-form-control has-spinner wpcf7-submit" />
                                          </p>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
