import React from "react";
import Counter from "./Counter";
const CounterList = () => {
    const counters = [
        { endValue: 5000, title: "Order", duration: 2000 },
        { endValue: 200, title: "products", duration: 3000 },
        { endValue: 500, title: "customers", duration: 2500 },
        { endValue: 4800, title: "delivered", duration: 1500 },
      ];
  
    return (
        <div
        className="vc_row wpb_row vc_row-fluid vc_custom_1501771135490 counter-container"
    >
      {counters.map((counter, index) => (
        <Counter
          key={index}
          endValue={counter.endValue}
          title={counter.title}
          duration={counter.duration}
        />
      ))}
    </div>
    );
  };
  export default CounterList;