import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { BASE_URL, BASE_IMAGE_URL } from "./config";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import DocumentTitle from './DocumentTitle';
const OrderSuccess = () => {
  DocumentTitle("ORDER SUCCESS | SYNTH - SOUNDS OF STREETWEAR");
  const { orderNumber } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/order-detail/${orderNumber}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOrderDetails(response.data);
      } catch (err) {
        setError("Failed to fetch order details.");
      }
    };

    fetchOrderDetails();
  }, [orderNumber, token]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!orderDetails || !orderDetails.data || !orderDetails.data.order) {
    return (
      <div className="loader-container">
        <ClipLoader size={50} />
      </div>
    );
  }

  const { order } = orderDetails.data;
  const subtotal = order.order_items.reduce((total, item) => {
    return total + item.product_unitprice * item.product_qty;
  }, 0);

  const grandTotal = subtotal;
  return (
    <>
      <div className="page-template-default page page-id-424 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-account woocommerce-page woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-header-standard eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-side-menu-slide-from-right eltdf-woocommerce-page eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />

            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div
                  className="eltdf-title  eltdf-standard-type eltdf-content-left-alignment"
                  style={{ height: 120, backgroundColor: "#fbf9f9" }}
                  data-height={120}
                >
                  <div className="eltdf-title-holder" style={{ height: 120 }}>
                    <div className="eltdf-container clearfix">
                      <div className="eltdf-container-inner">
                        <div className="eltdf-title-subtitle-holder">
                          <div className="eltdf-title-subtitle-holder-inner">
                            <h5 className="eltdf-page-title entry-title">
                              <span>Order Success</span>
                            </h5>
                            <div className="eltdf-breadcrumbs-holder">
                              <div className="eltdf-breadcrumbs">
                                <div className="eltdf-breadcrumbs-inner">
                                  <a href="/">Home</a>
                                  <span className="eltdf-delimiter">
                                    &nbsp; / &nbsp;
                                  </span>
                                  <span className="eltdf-current">
                                    Order Success
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="eltdf-container eltdf-default-page-template">
                  <div className="eltdf-container-inner clearfix">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-grid-col-4">
                        <div className="widget woocommerce widget_layered_nav woocommerce-widget-layered-nav">
                          <p className="woocommerce-notice woocommerce-notice--success woocommerce-thankyou-order-received">
                            Thank you. Your order has been received.
                          </p>
                          <ul className="woocommerce-order-overview woocommerce-thankyou-order-details order_details">
                            <li className="woocommerce-order-overview__order order">
                              <b> Order number: </b> {order.order_number}
                            </li>
                            <li className="woocommerce-order-overview__date date">
                              <b>Date:</b>
                              {new Date(order.order_date).toLocaleDateString()}
                            </li>
                            <li className="woocommerce-order-overview__email email">
                              <b>Email: </b>
                              {order.b_email}
                            </li>
                            <li className="woocommerce-order-overview__total total">
                              <b>Total: </b>
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    {order.total_amount}
                                  </span>
                                </bdi>
                              </span>
                            </li>
                            <li className="woocommerce-order-overview__payment-method method">
                              <b> Payment method: </b>
                              {order.payments.payment_method}
                            </li>
                            <li className="woocommerce-order-overview__payment-method method">
                              <b> Status: </b>
                              {order.status}
                            </li>
                            <li className="woocommerce-order-overview__payment-method method">
                              <b>Txn Id: </b>
                              {order.payments.txn_id}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div
                        className="eltdf-grid-col-8"
                        style={{ width: "100%", marginBottom: "50px" }}
                      >
                        <section className="woocommerce-order-details">
                          <h2 className="woocommerce-order-details__title">
                            Product Details
                          </h2>
                          <table
                            className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                            cellSpacing={0}
                          >
                            <thead>
                              <tr>
                                <th className="product-thumbnail">
                                  <span> Image </span>
                                </th>
                                <th>Product</th>
                                <th>Amount</th>
                                <th>Quantity</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.order_items.map((item) => (
                                <tr
                                  key={item.id}
                                  className="woocommerce-cart-form__cart-item cart_item"
                                >
                                  <td className="product-thumbnail">
                                    <a
                                      href={`/product-details/${item.products.product_slug}`}
                                    >
                                      <img
                                        width={70}
                                        height={70}
                                        src={`${BASE_IMAGE_URL}/${item.products.primary_image}`}
                                        className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                        alt={item.products.product_name}
                                        decoding="async"
                                        loading="lazy"
                                      />
                                    </a>
                                  </td>
                                  <td
                                    className="product-name"
                                    data-title="Product"
                                  >
                                    <a
                                      href={`/product-details/${item.products.product_slug}`}
                                    >
                                      {item.products.product_name}
                                    </a>
                                  </td>
                                  <td
                                    className="product-price"
                                    data-title="Price"
                                  >
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi> ₹ {item.product_unitprice}</bdi>
                                    </span>
                                  </td>
                                  <td
                                    className="product-quantity"
                                    data-title="Quantity"
                                  >
                                    {item.product_qty}
                                  </td>
                                  <td
                                    className="product-total"
                                    data-title="Total"
                                  >
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi>
                                        
                                        ₹
                                        {item.product_unitprice *
                                          item.product_qty}
                                      </bdi>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                              <tr className="cart-subtotal">
                                <th colSpan="4" style={{ textAlign: "left" }}>
                                  Subtotal
                                </th>
                                <td>
                                  <span className="woocommerce-Price-amount amount">
                                    <bdi> ₹{subtotal.toFixed(2)}</bdi>
                                  </span>
                                </td>
                              </tr>
                              <tr className="order-total">
                                <th colSpan="4" style={{ textAlign: "left" }}>
                                  Grand Total
                                </th>
                                <td>
                                  <strong>
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi> ₹{grandTotal.toFixed(2)}</bdi>
                                    </span>
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </section>

                        <section className="woocommerce-customer-details">
                          <section
                            className="custom-section woocommerce-columns--2 woocommerce-columns--addresses col2-set addresses"
                            style={{ display: "flex", marginLeft: "20px" }}
                          >
                            <div
                              className="custom-billing-column woocommerce-column--1 woocommerce-column--billing-address col-1"
                              style={{ flex: 1, paddingRight: "10px" }}
                            >
                              <h2 className="woocommerce-column__title">
                                Billing address
                              </h2>
                              {/* <address>
                                {order.b_address_line1}
                                <br />
                                {order.b_address_line2}
                                <br />
                                {order.b_city}
                                <br />
                                {order.b_state}
                                <br />
                                {order.b_pincode}
                                <br />
                                {order.b_country}
                                <p className="woocommerce-customer-details--phone">
                                  {order.b_contact}
                                </p>
                                <p className="woocommerce-customer-details--email">
                                  {order.b_email}
                                </p>
                              </address> */}
                              <address>
                                <span>
                                  {order.b_address_line1},
                                  {order.b_address_line2}
                                </span>
                                <br />
                                <span>
                                  {order.b_city}, {order.b_state}
                                </span>
                                <br />
                                <span>
                                  {order.b_country}, {order.b_pincode}
                                </span>
                                <br />
                                <span>
                                  {order.b_email},{order.b_contact}
                                </span>
                                <br />
                              </address>
                            </div>
                            <div
                              className="woocommerce-column woocommerce-column--2 woocommerce-column--shipping-address col-2"
                              style={{ flex: 1, paddingRight: "10px" }}
                            >
                              <h2 className="woocommerce-column__title">
                                Shipping address
                              </h2>
                              <address>
                                <span>
                                  {order.b_address_line1},
                                  {order.b_address_line2}
                                </span>
                                <br />
                                <span>
                                  {order.b_city}, {order.b_state}
                                </span>
                                <br />
                                <span>
                                  {order.b_country}, {order.b_pincode}
                                </span>
                                <br />
                                <span>
                                  {order.b_email},{order.b_contact}
                                </span>
                                <br />
                              </address>
                            </div>
                          </section>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
