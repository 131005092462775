import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { BASE_URL } from "../Components/config";



 export const handleLogout = async (e) => {
  
    e.preventDefault();
   
    const token = localStorage.getItem('token');
    
    try {
      const response = await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.success) {
        toast.success("User logout successfully", {
          autoClose: 2000,
        });
        setTimeout(() => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        }, 2000);
        
      } else {
        toast.error('Failed to log out. Please try again.'); 
      }
    } catch (error) {
      console.error('Error logging out:', error);
      // alert('Failed to log out. Please try again.');
      toast.error('Failed to log out. Please try again.');
    }
  };

 