import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "./Footer";
import Header from "./Header";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { fetchBanner } from "../Api/BannerApi";
import { fetchCategories } from "../Api/CategoryApi";
import { BASE_IMAGE_URL, BASE_URL } from "./config";
import CounterList from "./CounterList";
import { addToCart } from "../Api/CartApi/AddToCartApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import DocumentTitle from './DocumentTitle';
const Main = () => {
  DocumentTitle("HOME | SYNTH - SOUNDS OF STREETWEAR");
  const token = localStorage.getItem("token");
  const [banners, setBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [arrivals, setArrivals] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bannerData = await fetchBanner(token);
        setBanners(bannerData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const categoryData = await fetchCategories(token);
        setCategories(categoryData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategory();
  }, [token]);

  useEffect(() => {
    const fetchArrivalProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/arrival-product-list`);
        if (response.data.success) {
          setArrivals(response.data.data.arrivals);
        }
      } catch (error) {
        console.error("Error fetching arrival products:", error);
      }
    };

    fetchArrivalProducts();
  }, []);

  const handleAddToCart = async (productId) => {
    try {
      const response = await addToCart(productId, token, navigate);
      if (response.success) {
        toast.success("Product successfully added in cart", {
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error( "There was an error adding your product",{
          autoClose: 3000,
        })
      }
    } catch (error) {
      toast.error("Plasse first login then add the product", {
        autoClose: 3000,
      });
    }
  };
  
  return (
    <>
      <div className="home page-template page-template-full-width page-template-full-width-php page page-id-381 theme-satine elated-core-1.3.1 eltdf-social-login-1.0.2 woocommerce-no-js qodef-qi--no-touch qi-addons-for-elementor-1.5.5 satine-ver-1.9 eltdf-grid-1300 eltdf-top-bar-enabled eltdf-header-standard eltdf-header-standard-right-position eltdf-menu-area-shadow-disable eltdf-menu-area-in-grid-shadow-disable eltdf-menu-area-border-disable eltdf-menu-area-in-grid-border-disable eltdf-logo-area-border-disable eltdf-logo-area-in-grid-border-disable eltdf-header-vertical-shadow-disable eltdf-header-vertical-border-disable eltdf-sticky-header-on-scroll-down-up eltdf-default-mobile-header eltdf-sticky-up-mobile-header eltdf-dropdown-animate-height eltdf-full-width-wide-menu eltdf-top-bar-light eltdf-side-menu-slide-from-right eltdf-woocommerce-columns-3 eltdf-woo-normal-space eltdf-woo-pl-info-below-image eltdf-woo-pl-hide-ratings eltdf-search-covers-header wpb-js-composer js-comp-ver-6.10.0 vc_responsive elementor-default elementor-kit-3738">
        <div className="eltdf-wrapper">
          <div className="eltdf-wrapper-inner">
            <Header />
            <div className="eltdf-content">
              <div className="eltdf-content-inner">
                <div className="eltdf-full-width">
                  <div className="eltdf-full-width-inner">
                    <div className="eltdf-grid-row">
                      <div className="eltdf-page-content-holder eltdf-grid-col-12">
                        <section className="wpb-content-wrapper">
                          <div className="vc_row wpb_row vc_row-fluid vc_custom_1503563565318 vc_row-has-fill">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner vc_custom_1503561231409">
                                  <div className="wpb_wrapper">
                                    {loading ? (
                                      <div className="loader-container">
                                        <ClipLoader size={50} />
                                      </div>
                                    ) : (
                                      <Swiper
                                        slidesPerView={1}
                                        spaceBetween={20}
                                        loop={true}
                                        pagination={{ clickable: true }}
                                        navigation={true}
                                        modules={[Pagination, Navigation]}
                                        className="mySwiper"
                                      >
                                        {banners.map((banner) => (
                                          <SwiperSlide key={banner.id}>
                                            <div className="slide">
                                              <Link to='/shop'>
                                              <img
                                                src={`${BASE_IMAGE_URL}/${banner.image}`}
                                                alt="Banner"
                                              />
                                              </Link>
                                             
                                            </div>
                                          </SwiperSlide>
                                        ))}
                                      </Swiper>
                                    )}
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div className="full-width-bg-black">
                          <div className="eltdf-row-grid-section">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1503499391457">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div  className="eltdf-section-title-holder"  style={{ textAlign: "center" }}  >
                                      <div className="eltdf-st-subtitle-holder">
                                        <h6 className="eltdf-st-subtitle white-text" >
                                          <span>Best Fashion categories</span>
                                        </h6>
                                      </div>
                                      <h2 className="eltdf-st-title white-text" >
                                        <span>Best categories </span>
                                      </h2>
                                    </div>
                                    <div className="eltdf-blog-list-holder eltdf-bl-standard eltdf-bl-three-columns eltdf-bl-normal-space " style={{ marginTop: "40px" }} >
                                      <div className="eltdf-bl-wrapper" >
                                        <ul className="eltdf-blog-list" >
                                          {categories.map((category) => (
                                            <li
                                              className="eltdf-bl-item clearfix"
                                              key={category.id}
                                              
                                            >
                                              <div className="eltdf-bli-inner">
                                                <div className="eltdf-post-image">
                                                  <a
                                                    itemProp="url"
                                                    href={`/shop/cat_slug=${category.slug}`}
                                                    title={category.title}
                                                  >
                                                    <img
                                                      src={`${BASE_IMAGE_URL}/${category.image}`}
                                                      alt={category.title}
                                                      decoding="async"
                                                      className="category-resize-img attachment-full size-full wp-post-image"
                                                    />
                                                  </a>
                                                </div>
                                                <div className="eltdf-bli-content" >
                                                  <h3
                                                    itemProp="name"
                                                    className="entry-title eltdf-post-title white-text"
                                                  >
                                                    <Link
                                                      itemProp="url"
                                                      href={`/shop/cat_slug=${category.slug}`}
                                                      title={category.title}
                                                      className="custom-hover"
                                                    >
                                                      {category.title}
                                                    </Link>
                                                  </h3>
                                                  <div className="eltdf-bli-info"></div>
                                                  <div className="eltdf-bli-excerpt" >
                                                    <div className="eltdf-post-read-more-button">
                                                      <Link
                                                        itemProp="url"
                                                        to={`/shop/cat_slug=${category.slug}`}
                                                        target="_self"
                                                        style={{ fontSize: 11, fontWeight: 400, }}
                                                        className="eltdf-btn eltdf-btn-medium eltdf-btn-simple eltdf-btn-icon eltdf-blog-list-button eltdf-icon-white"
                                                      >
                                                        <span className="cat-read" >
                                                          Read more
                                                        </span>
                                                        <i className="fa-solid fa-arrow-right-long"></i>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div className="full-width-bg-black">
                          <div className="eltdf-row-grid-section">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1502796023597">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div
                                      className="eltdf-section-title-holder"
                                      style={{ textAlign: "center" }}
                                    >
                                      <div className="eltdf-st-subtitle-holder">
                                        <h6 className="eltdf-st-subtitle white-text">
                                          <span>Best Fashion Deals</span>
                                        </h6>
                                      </div>
                                      <h2 className="eltdf-st-title white-text">
                                        <span>New Arrivals Products</span>
                                      </h2>
                                      <p className="eltdf-st-text white-text">
                                        Discover the newest additions to our
                                        collection that have just landed. From
                                        sleek designs to vibrant colors, these
                                        pieces are crafted to perfection to
                                        elevate your style and meet your needs.
                                        Whether you’re looking for something
                                        chic, trendy, or timeless, our new
                                        arrivals have something for everyone.
                                      </p>
                                    </div>
                                    
                                    <div className="eltdf-pl-holder eltdf-standard-layout eltdf-normal-space eltdf-four-columns eltdf-info-below-image">
                                      <div className="eltdf-pl-outer">
                                        {arrivals.map((item) => (
                                          <div
                                            key={item.product.id}
                                            className="eltdf-pli "
                                          >
                                            <div className="eltdf-pli-inner">
                                              <div className="eltdf-post-image">
                                                <Link
                                                  to={`/product-details/${item.product.product_slug}`}
                                                >
                                                  <img
                                                    src={`${BASE_IMAGE_URL}/${item.product.primary_image}`}
                                                    alt={
                                                      item.product.product_name
                                                    }
                                                    className="arrival-resize-img"
                                                  />
                                                </Link>
                                              </div>
                                              <div className="eltdf-pli-add-to-cart">
                                                <Link
                                                  rel="nofollow"
                                                  to="#"
                                                  data-quantity={1}
                                                  className="eltdf-button"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    handleAddToCart(
                                                      item.product.id
                                                    );
                                                  }}
                                                >
                                                  Add to cart
                                                  <span className="eltdf-add-to-cart-icon icon-arrows-circle-plus"></span>
                                                </Link>
                                              </div>
                                            </div>
                                            <div
                                              className="eltdf-pli-text-wrapper"
                                              style={{ textAlign: "center" }}
                                            >
                                              <h5
                                                itemProp="name"
                                                className="entry-title eltdf-pli-title white-text"
                                              >
                                                <Link
                                                  itemProp="url"
                                                  to={`/product-details/${item.product.product_slug}`}
                                                  className="custom-hover"
                                                >
                                                  {item.product.product_name}
                                                </Link>
                                              </h5>
                                              <div className="eltdf-pli-price">
                                                <span className="woocommerce-Price-amount amount">
                                                  <bdi >
                                                    ₹{item.product.base_price}
                                                  </bdi>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div className="vc_row wpb_row vc_row-fluid">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                              <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                  <div className="eltdf-parallax-holder eltdf-vertical-middle-align">
                                    <div className="eltdf-parallax-inner">
                                      <div className="eltdf-elements-holder eltdf-responsive-mode-768">
                                     
                                        <div className="image-container">
                                          <img src="../wp-content/uploads/2017/07/home-full-1.jpg" alt="img not found"></img>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="full-width-bg-black">
                          <div className="eltdf-row-grid-section">
                            <div  className="vc_row wpb_row vc_row-fluid vc_custom_1501771135490">
                              <CounterList />
                            </div>
                          </div>
                          </div>
                          <div style={{textAlign:"center"}}>
                              <img src="../wp-content/uploads/2017/07/getintouch.jpg" alt="img not found"></img>
                          </div>
                          <div className="eltdf-row-grid-section">
                            <div className="vc_row wpb_row vc_row-fluid vc_custom_1503063920838">
                                   <div className="eltdf-section-title-holder" style={{ textAlign: "center", marginBottom:'50px'}}> 
                                   <div className="eltdf-st-subtitle-holder"><h6 className="eltdf-st-subtitle"><span>Orders and delivery</span></h6></div>
                                      <h2 className="eltdf-st-title">
                                        <span>Our Unique Offerings</span>
                                      </h2>
                                    </div>
                              <div className="wpb_column vc_column_container vc_col-sm-8 vc_col-lg-4 vc_col-md-offset-0 vc_col-md-4 vc_col-sm-offset-2">
                                <div className="vc_column-inner vc_custom_1502180798394">
                               
                                  <div className="wpb_wrapper" >
                                 
                                    <div className="eltdf-iwt clearfix eltdf-iwt-icon-top eltdf-iwt-icon-medium">
                                      <div className="eltdf-iwt-icon">
                                        <span className="eltdf-icon-shortcode eltdf-normal eltdf-icon-medium">
                                        <i className="fa-solid fa-location-dot"></i>
                                        </span>
                                      </div>
                                      <div className="eltdf-iwt-content">
                                        <h5 className="eltdf-iwt-title">
                                          <span className="eltdf-iwt-title-text ">
                                          Delivery across India by <b>Shop.Synth</b>
                                          </span>
                                        </h5>
                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-8 vc_col-lg-4 vc_col-md-offset-0 vc_col-md-4 vc_col-sm-offset-2">
                                <div className="vc_column-inner vc_custom_1502180808448">
                                  <div className="wpb_wrapper">
                                    <div className="eltdf-iwt clearfix eltdf-iwt-icon-top eltdf-iwt-icon-medium">
                                      <div className="eltdf-iwt-icon">
                                        <span className="eltdf-icon-shortcode eltdf-normal eltdf-icon-medium">
                                        <i className="fa-regular fa-star"></i>
                                        </span>
                                      </div>
                                      <div className="eltdf-iwt-content">
                                        <h5 className="eltdf-iwt-title">
                                          <span className="eltdf-iwt-title-text">
                                          Free delivery on all orders within Faridabad
                                          </span>
                                        </h5>
                                       
                                      </div>
                                    </div>
                                   
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-8 vc_col-lg-4 vc_col-md-offset-0 vc_col-md-4 vc_col-sm-offset-2">
                                <div className="vc_column-inner vc_custom_1502180818266">
                                  <div className="wpb_wrapper">
                                    <div className="eltdf-iwt clearfix eltdf-iwt-icon-top eltdf-iwt-icon-medium">
                                      <div className="eltdf-iwt-icon">
                                        <span className="eltdf-icon-shortcode eltdf-normal eltdf-icon-medium">
                                        <i className="fa-solid fa-bag-shopping"></i>
                                          
                                        </span>
                                      </div>
                                      <div className="eltdf-iwt-content">
                                        <h5 className="eltdf-iwt-title">
                                          <span className="eltdf-iwt-title-text">
                                          All orders will be delivered by <b>Shop.Synth</b>
                                          </span>
                                        </h5>
                                       
                                      </div>
                                    </div>
                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="eltdf-row-grid-section">
                            <div className="vc_row wpb_row vc_row-fluid">
                                   <div className="eltdf-section-title-holder" style={{ textAlign: "center",marginBottom:"50px" }}> 
                                   <div className="eltdf-st-subtitle-holder"><h6 className="eltdf-st-subtitle"><span>Our Policy</span></h6></div>
                                      <h2 className="eltdf-st-title">
                                        <span>Cancellation policy</span>
                                      </h2>
                                      <h6>
                                      Full refund if you cancel it before the order is accepted by us. For any queries on cancellations reach out to us via chat
                                      </h6>
                                    </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
